// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-detail-index-tsx": () => import("./../src/detail/index.tsx" /* webpackChunkName: "component---src-detail-index-tsx" */),
  "component---src-pages-index-ts": () => import("./../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-topics-ts": () => import("./../src/pages/topics.ts" /* webpackChunkName: "component---src-pages-topics-ts" */),
  "component---src-profile-index-tsx": () => import("./../src/profile/index.tsx" /* webpackChunkName: "component---src-profile-index-tsx" */)
}

